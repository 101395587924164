import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import { RelationshipFieldMany } from "../plugins/Cruxtify/field-types/RelationshipFieldMany";
import { StringField } from "../plugins/Cruxtify/field-types/StringField";
import {
  NumberProperty,
  RelationshipPropertyMany,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class ResourceType extends CrudModel {
  public static PAID_IDS = [4];
  protected static _typeLabel = "Resource Type";
  protected static asProperty = "resource_type";
  public static api = {
    path: "resource-types",
    fullCache: true,
  };

  protected userIdField = [];
  protected static routeBase = "resource-types";

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: NumberProperty,
      opts: {
        name: "legacy_id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },

    { type: StringProperty, opts: { name: "name", label: "Name" } },

    // relationships
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "resources",
        label: "Resources",
        relatedModel: "Resource",
        foreignProperty: "resource_type",
      },
    },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,

    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },

    // relationships
    {
      type: RelationshipFieldMany,
      opts: {
        property: "resources",
        collectionLayoutId: "datatable",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: ["name"],
      filterFields: [],
      searchLabel: "Search",
      defaultSort: [
        {
          field: "name",
          order: "asc",
        },
      ],
    },
  ];
  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "modellayout",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    fields: ["name", "id", "created_at", "updated_at"],
                  },
                ],
              },
            },
          },
          {
            id: "resources",
            label: "Resources",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "resources",
                    fields: ["resources"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}

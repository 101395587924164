import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
} from "../plugins/Cruxtify/UserPermission";
import {
  RelationshipProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";

import {
  RelationshipField,
  SelectField,
  StringField,
} from "../plugins/Cruxtify/field-types";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

export class Notification extends CrudModel {
  protected static _typeLabel = "Notification";
  protected static asProperty = "notification";
  public static api = {
    path: "notifications",
  };

  protected userIdField = [];
  protected static routeBase = "notifications";

  protected static userPermissions = new UserPermissions(UserPermission.Read);

  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: {
        name: "email_address",
        label: "Email Address",
        description: "This is the email address the notification was sent to.",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "status",
        label: "Delivery Status",
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "mandrill_id",
        label: "Mandrill ID",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "studio",
        label: "Studio",
        relatedModel: "Studio",
        foreignProperty: "notifications",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "course",
        label: "Course",
        relatedModel: "Course",
        foreignProperty: "notifications",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "trainer",
        label: "Trainer",
        relatedModel: "Trainer",
        foreignProperty: "notifications",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "contract",
        label: "Contract",
        relatedModel: "Contract",
        foreignProperty: "notifications",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "mailer_template",
        label: "Email Template",
        relatedModel: "MailerTemplate",
        foreignProperty: "notifications",
      },
    },
  ];

  protected static fieldDefinitions = [
    {
      type: "NumberField",
      opts: {
        property: "id",
      },
    },
    {
      type: "DateTimeField",
      opts: {
        property: "created_at",
        label: "Sent At",
      },
    },
    {
      type: "DateTimeField",
      opts: {
        property: "updated_at",
      },
    },
    {
      type: "DateTimeField",
      opts: {
        property: "deleted_at",
      },
    },

    {
      type: StringField,
      opts: {
        property: "email_address",
      },
    },
    {
      type: StringField,
      opts: {
        property: "mailgun_id",
      },
    },
    {
      type: SelectField,
      opts: {
        property: "status",
        options: {
          sent: "Queued to Send",
          send: "Sent",
          deferral: "Deferral",
          hard_bounce: "Hard Bounced",
          soft_bounce: "Soft Bounced",
          delivered: "Delivered",
          open: "Opened",
          click: "Clicked",
          spam: "User Reported Spam",
          unsub: "Unsubscribed",
          reject: "Rejected",
        },
      },
    },

    // relationships
    {
      type: RelationshipField,
      opts: {
        property: "trainer",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "studio",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "course",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "contract",
      },
    },
    {
      type: RelationshipField,
      opts: {
        property: "mailer_template",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      inlineEdit: false,
      useModal: true,
      columnFields: [
        "created_at",
        "email_address",
        "status",
        "mailer_template",
        "trainer",
        "course",
        "studio",
        "contract",
      ],
      enableDynamicFilters: true,
      enableSearch: true,
      searchLabel: "Search",
      // useDynamicFields: false,
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "default",
      fieldSets: [
        {
          id: "name",
          fields: ["email_address", "status"],
        },
        {
          id: "rel",
          fields: [
            "mailer_template",
            "contract",
            "course",
            "studio",
            "trainer",
          ],
        },
        {
          id: "meta",
          fields: ["id", "created_at", "updated_at", "mailgun_id"],
        },
      ],
    },
  ];
}

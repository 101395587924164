import { RequestField } from "../fields";
import { CrudFilter } from "../plugins/Cruxtify/CrudFilter";
import { CrudModel } from "../plugins/Cruxtify/CrudModel";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  AddressField,
  BooleanField,
  EmailField,
  FieldGroupField,
  ImageField,
  PhoneField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
  UrlField,
  WysiwygField,
} from "../plugins/Cruxtify/field-types";
import { AddressFieldsEnum } from "../plugins/Cruxtify/field-types/AddressField";
import {
  ArrayStringProperty,
  BooleanProperty,
  EmailProperty,
  ImageProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
  StringProperty,
  UrlProperty,
} from "../plugins/Cruxtify/property-types";
import { Country } from "./Country";
import { Studio } from "./Studio";
import { extendWithDeleted } from "./collection-layouts/extend-with-deleted";
import { idAndTimestamps } from "./property-and-field-groups/id-and-timestamps";

const singleAutoPopOpts = {
  populate: [
    // "notes",
    // "notes.author",
    // "contracts",
    // "contracts.contract_events",
    // "trainer_qualifications",
    // "completed_courses",
    // "notifications",
    // "blocked_notifications",
    // "events",
  ],
};

export class Trainer extends CrudModel {
  protected static _typeLabel = "Trainer";
  protected static asProperty = "trainer";
  public static api = {
    path: "trainers",
  };
  protected static routeBase = "trainers";
  protected userIdField = ["user"];

  protected labelGlue: string = " ";

  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.SuperUser]: UserPermission.Read,
    },
    UserPermission.Read
  );

  // public static modalEdit = false;
  protected static propertyDefinitions = [
    ...idAndTimestamps.properties,

    {
      type: StringProperty,
      opts: {
        name: "legacy_id",
        label: "Legacy ID",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Read,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "older_legacy_id",
        label: "Older Legacy ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "native_fullname",
        label: "Display Name",
        cols: 12,
        description:
          "If set, this is how the trainer's name will appear on the website." +
          " \r\n \r\n " +
          "For Example, for those who want name displayed in " +
          "non-roman alphabet ,i.e. 中文名, 日本の名前, русское имя. " +
          " \r\n \r\n " +
          "Or for those who want special letters/titles written after their " +
          "last name, i.e. Jane Doe, Ph.D, D.P.T.",
      },
    },

    { type: StringProperty, opts: { name: "fullname_by_last" } },
    {
      type: StringProperty,
      opts: { name: "first_name", label: "First Name", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: { name: "middle_name", label: "Middle Name" },
    },
    {
      type: StringProperty,
      opts: { name: "last_name", label: "Last Name", rules: ["required"] },
    },
    {
      type: StringProperty,
      opts: { name: "full_name", label: "Full Name", isRemotelyComputed: true },
    },
    { type: ImageProperty, opts: { name: "s3_id", label: "Profile Photo" } },
    {
      type: StringProperty,
      opts: { name: "address1", label: "Address Line 1" },
    },
    {
      type: StringProperty,
      opts: { name: "address2", label: "Address Line 2" },
    },
    { type: StringProperty, opts: { name: "city", label: "City" } },
    { type: StringProperty, opts: { name: "state", label: "State" } },
    { type: StringProperty, opts: { name: "substate", label: "Substate" } },
    { type: StringProperty, opts: { name: "zip", label: "Zip" } },
    { type: StringProperty, opts: { name: "bio", label: "Bio" } },
    {
      type: UrlProperty,
      opts: { name: "facebook", label: "Facebook Profile URL" },
    },
    {
      type: UrlProperty,
      opts: { name: "twitter", label: "Twitter Profile URL" },
    },
    { type: StringProperty, opts: { name: "phone", label: "Phone" } },
    {
      type: EmailProperty,
      opts: {
        name: "email",
        label: "Private Email",
        description:
          "This email will be used for internal communications from Gyrotonic HQ.",
        rules: ["required"],
      },
    },
    { type: UrlProperty, opts: { name: "website", label: "Website" } },
    {
      type: StringProperty,
      opts: { name: "mobile_phone", label: "Mobile Phone" },
    },
    {
      type: StringProperty,
      opts: {
        name: "email_public",
        label: "Public Email",
        description:
          "This email will be displayed on the public Trainer profile.",
      },
    },
    { type: StringProperty, opts: { name: "fax", label: "Fax Number" } },
    {
      type: UrlProperty,
      opts: { name: "instagram", label: "Instagram Profile URL" },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_email_opted_out",
        label: "Opt Out of Non-essential Email",
      },
    },
    {
      type: StringProperty,
      opts: { name: "youtube", label: "Youtube Profile URL" },
    },
    { type: StringProperty, opts: { name: "wechat", label: "WeChat ID" } },
    {
      type: StringProperty,
      opts: { name: "addendum_agreed", userPermissions: UserPermission.Hidden },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "has_expired_gyrotonic_license",
        label: "Expired Gyrotonic License",
        isRemotelyComputed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "has_active_gyrotonic_license",
        label: "Active Gyrotonic License",
        isRemotelyComputed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "has_expired_gyrokinesis_license",
        label: "Expired Gyrokinesis License",
        isRemotelyComputed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "has_active_gyrokinesis_license",
        label: "Active Gyrokinesis License",
        isRemotelyComputed: true,
      },
    },
    {
      type: BooleanProperty,
      opts: {
        name: "is_active",
        label: "Is Active",
        description:
          "A trainer is considered active if they have at least one active license.",
        isRemotelyComputed: true,
      },
    },
    {
      type: ArrayStringProperty,
      opts: {
        name: "completed_course_names",
        label: "Completed Courses",
        isRemotelyComputed: true,
      },
    },

    // relationships
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "Owning User",
        relatedModel: "User",
        foreignProperty: "trainers",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "country",
        label: "Country",
        relatedModel: "Country",
        rules: ["required"],
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "country_req",
        label: "Requested Country",
        relatedModel: "Country",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "trainer_qualifications",
        label: "Qualifications",
        relatedModel: "TrainerQualification",
        foreignProperty: "trainer",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "completed_courses",
        label: "Courses",
        relatedModel: "CompletedCourse",
        foreignProperty: "trainer",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "instructor_of",
        label: "Exclusive Intructor Of",
        relatedModel: "Course",
        foreignProperty: "instructors",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "contracts",
        label: "Licenses",
        relatedModel: "Contract",
        foreignProperty: "trainer",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Read
        ),
        newModelDefaults: (trainer) => {
          // handle EU countries
          const country = Country.isEu(trainer.getProperty("country").value)
            ? Country.EU_ID
            : trainer.getProperty("country").value;

          return {
            signer_name: trainer.getProperty("full_name").stringValue,
            signer_address: trainer.findField("address").stringValue,
            signer_email: trainer.getProperty("email").value,
            signer_country: trainer.getProperty("country").stringValue,
            signer_title: "Gyrotonic Trainer",
            country,
          };
        },
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "events",
        label: "Events",
        relatedModel: "Event",
        foreignProperty: "trainers",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notes",
        label: "Notes",
        relatedModel: "Note",
        foreignProperty: "trainer",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "notifications",
        label: "Notifications",
        relatedModel: "Notification",
        foreignProperty: "trainer",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "studios",
        label: "Studios",
        relatedModel: "Studio",
        foreignProperty: "trainers",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "blocked_studios",
        label: "Blocked From Studios",
        relatedModel: "Studio",
        foreignProperty: "blocked_trainers",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },

    // req
    { type: StringProperty, opts: { name: "first_name_req" } },
    { type: StringProperty, opts: { name: "last_name_req" } },
    { type: StringProperty, opts: { name: "middle_name_req" } },
    { type: StringProperty, opts: { name: "address1_req" } },
    { type: StringProperty, opts: { name: "address2_req" } },
    { type: StringProperty, opts: { name: "facebook_req" } },
    { type: StringProperty, opts: { name: "twitter_req" } },
    { type: StringProperty, opts: { name: "youtube_req" } },
    { type: StringProperty, opts: { name: "instagram_req" } },
    { type: StringProperty, opts: { name: "wechat_req" } },
    { type: StringProperty, opts: { name: "email_req" } },
    { type: StringProperty, opts: { name: "email_public_req" } },
    { type: StringProperty, opts: { name: "website_req" } },
    { type: StringProperty, opts: { name: "city_req" } },
    { type: StringProperty, opts: { name: "substate_req" } },
    { type: StringProperty, opts: { name: "native_fullname_req" } },
  ];

  protected static fieldDefinitions = [
    ...idAndTimestamps.fields,
    {
      type: StringField,
      opts: { property: "legacy_id" },
    },
    {
      type: StringField,
      opts: {
        property: "older_legacy_id",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: RequestField,
      opts: {
        type: StringField,
        cols: 12,
        property: "native_fullname",
        fieldWidth: "full",
        description:
          "If set, this is how the trainer's name will appear on the website." +
          " \r\n \r\n " +
          "For Example, for those who want name displayed in " +
          "non-roman alphabet, i.e. 中文名, 日本の名前, русское имя. " +
          " \r\n \r\n " +
          "Or for those who want special letters/titles written after their " +
          "last name, i.e. Jane Doe, Ph.D, D.P.T.",
      },
    },
    {
      type: FieldGroupField,
      opts: {
        id: "fullname",
        label: "Name (Roman Alphabet/English)",
        isPrimaryLabelField: 1,
        subFields: [
          {
            type: RequestField,
            opts: {
              type: StringField,
              property: "first_name",
            },
          },
          {
            type: RequestField,
            opts: {
              type: StringField,
              property: "middle_name",
            },
          },
          {
            type: RequestField,
            opts: {
              type: StringField,
              property: "last_name",
            },
          },
        ],
      },
    },
    { type: ImageField, opts: { property: "s3_id" } },
    { type: WysiwygField, opts: { property: "bio" } },
    {
      type: RequestField,
      opts: { type: UrlField, property: "facebook" },
    },
    {
      type: RequestField,
      opts: { type: UrlField, property: "twitter" },
    },
    { type: PhoneField, opts: { property: "phone" } },
    {
      type: RequestField,
      opts: {
        type: EmailField,
        property: "email",
        label: "Private Email",
        description:
          "This email will be used for internal communications from Gyrotonic HQ.",
        cols: 6,
      },
    },
    {
      type: RequestField,
      opts: { type: UrlField, property: "website", cols: 12 },
    },
    { type: PhoneField, opts: { property: "mobile_phone" } },
    {
      type: RequestField,
      opts: {
        type: EmailField,
        property: "email_public",
        description:
          "This email will be displayed on the public Trainer profile.",
        cols: 6,
      },
    },
    { type: StringField, opts: { property: "fax" } },
    {
      type: RequestField,
      opts: { type: UrlField, property: "instagram" },
    },
    { type: BooleanField, opts: { property: "is_email_opted_out" } },
    {
      type: RequestField,
      opts: { type: UrlField, property: "youtube" },
    },
    { type: RequestField, opts: { type: StringField, property: "wechat" } },
    { type: StringField, opts: { property: "state" } },
    { type: StringField, opts: { property: "addendum_agreed" } },
    { type: BooleanField, opts: { property: "is_active" } },
    { type: BooleanField, opts: { property: "has_expired_gyrotonic_license" } },
    { type: BooleanField, opts: { property: "has_active_gyrotonic_license" } },
    {
      type: BooleanField,
      opts: { property: "has_expired_gyrokinesis_license" },
    },
    {
      type: BooleanField,
      opts: { property: "has_active_gyrokinesis_license" },
    },

    // relationships
    { type: RelationshipField, opts: { property: "user" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "trainer_qualifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "trainer_qualifications_trainer",
        property: "trainer_qualifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          disableRowClick: true,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "completed_courses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "completed_courses_trainer",
        property: "completed_courses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          disableRowClick: true,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "completed_courses_tooltip",
        property: "completed_courses",
        viewComponentName: "trainer-courses-tooltip",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "all_contracts",
        property: "contracts",
        collectionLayoutId: "admin_field",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "current_contracts",
        property: "contracts",
        label: "Current Licenses",
        collectionLayoutId: "admin_field",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          staticPropertyFilters: {
            is_current: true,
          },
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "current_contracts_trainer",
        property: "contracts",
        label: "Current Licenses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutId: "trainer",
        collectionLayoutOpts: {
          staticPropertyFilters: {
            is_current: true,
          },
          disableRowClick: true,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "past_contracts",
        property: "contracts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "past_contracts_trainer",
        property: "contracts",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
        collectionLayoutOpts: {
          staticPropertyFilters: {
            is_current: false,
          },
          disableRowClick: true,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notes",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "studios",
        fieldWidth: "medium",
        collectionLayoutOpts: {
          filters: [
            ...Studio.publicVisibilityFilters,
            new CrudFilter({
              name: "hide_blocked",
              queryValue: "1",
              isStatic: true,
            }),
          ],
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "blocked_studios",
        fieldWidth: "medium",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "notifications",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },

    // other
    {
      type: AddressField,
      opts: {
        id: "address",
        label: "Address",
        disabledSubFields: [
          AddressFieldsEnum.LATITUDE,
          AddressFieldsEnum.LONGITUDE,
        ],
        subFieldTypes: {
          [AddressFieldsEnum.COUNTRY]: RequestField,
          [AddressFieldsEnum.CITY]: RequestField,
          [AddressFieldsEnum.STREET_1]: RequestField,
          [AddressFieldsEnum.STREET_2]: RequestField,
        },
        subFieldOpts: {
          [AddressFieldsEnum.COUNTRY]: {
            type: RelationshipField,
            property: "country",
            label: "Country",
            md: 12,
          },
          [AddressFieldsEnum.DIVISION_1]: {
            property: "state",
            label: "State",
            md: 3,
          },
          [AddressFieldsEnum.DIVISION_2]: {
            type: StringField,
            property: "substate",
            label: "Substate",
            md: 3,
          },
          [AddressFieldsEnum.CITY]: {
            type: StringField,
            property: "city",
            md: 4,
          },
          [AddressFieldsEnum.STREET_1]: {
            type: StringField,
            property: "address1",
            md: 12,
            label: "Street",
          },
          [AddressFieldsEnum.STREET_2]: {
            type: StringField,
            property: "address2",
            label: "Street Line 2",
            md: 12,
          },
          [AddressFieldsEnum.POSTAL_CODE]: {
            type: StringField,
            property: "zip",
            label: "Zip",
            md: 2,
          },
        },
      },
    },

    // filters
    {
      type: RelationshipFieldMany,
      opts: {
        id: "filter_qualifications",
        property: "trainer_qualifications",
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        id: "filter_courses",
        property: "completed_courses",
      },
    },
    {
      type: StringField,
      opts: { id: "filter_first_name", property: "first_name" },
    },
    {
      type: StringField,
      opts: { id: "filter_middle_name", property: "middle_name" },
    },
    {
      type: StringField,
      opts: { id: "filter_last_name", property: "last_name" },
    },
    {
      type: StringField,
      opts: { id: "filter_facebook", property: "facebook" },
    },
    { type: StringField, opts: { id: "filter_twitter", property: "twitter" } },
    { type: StringField, opts: { id: "filter_email", property: "email" } },
    { type: StringField, opts: { id: "filter_website", property: "website" } },
    {
      type: StringField,
      opts: { id: "filter_email_public", property: "email_public" },
    },
    {
      type: StringField,
      opts: { id: "filter_instagram", property: "instagram" },
    },
    { type: StringField, opts: { id: "filter_youtube", property: "youtube" } },
    {
      type: StringField,
      opts: { id: "filter_native_fullname", property: "native_fullname" },
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "FieldSetGridLayout",
      opts: {
        id: "flat-layout",
        modelRequestOptions: singleAutoPopOpts,
        fieldSets: [
          {
            id: "name",
            // title: "Name",
            fields: ["fullname", "native_fullname", "id", "legacy_id"],
          },

          {
            id: "notes",
            fields: ["notes"],
          },

          {
            id: "notifications",
            fields: ["notifications"],
          },

          {
            id: "contact_info",
            // title: "Contact Information",
            fields: [
              "address",
              "phone",
              "mobile_phone",
              "wechat",
              "email",
              "email_public",
            ],
          },

          {
            id: "contracts",
            fields: [
              // "has_expired_gyrotonic_license",
              // "has_expired_gyrokinesis_license",
              "has_active_gyrotonic_license",
              "has_active_gyrokinesis_license",
              "all_contracts",
            ],
          },

          {
            id: "qualifications",
            fields: ["trainer_qualifications"],
          },

          {
            id: "completed_courses",
            fields: ["completed_courses"],
          },

          {
            id: "studios",
            fields: ["studios", "blocked_studios"],
          },

          {
            id: "about",
            // title: "About",
            fields: ["s3_id", "bio", "website"],
          },
          {
            id: "social",
            // title: "Social Media",
            fields: ["facebook", "twitter", "instagram", "youtube"],
          },
        ],
      },
    },
    {
      type: "TabbedModelLayout",
      opts: {
        id: "trainer-tabs",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "name",
                    // title: "Name",
                    fields: ["fullname", "native_fullname"],
                  },
                  {
                    id: "contact_info",
                    // title: "Contact Information",
                    fields: [
                      "address",
                      "phone",
                      "mobile_phone",
                      "email_public",
                      "email",
                    ],
                  },
                  {
                    id: "about",
                    // title: "About",
                    fields: ["s3_id", "bio", "website"],
                  },
                  {
                    id: "social",
                    // title: "Social Media",
                    fields: [
                      "facebook",
                      "twitter",
                      "instagram",
                      "youtube",
                      "wechat",
                    ],
                  },
                ],
              },
            },
          },
          {
            id: "LicensesTab",
            label: "Licensing, Qualifications & Courses",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "current_contracts_trainer",
                    fields: [
                      // "has_expired_gyrotonic_license",
                      // "has_expired_gyrokinesis_license",
                      "current_contracts_trainer",
                    ],
                  },
                  {
                    id: "qualifications",
                    fields: ["trainer_qualifications_trainer"],
                  },
                  {
                    id: "completed_courses",
                    fields: ["completed_courses_trainer"],
                  },
                ],
              },
            },
          },

          // {
          //   id: "LicensesTabPast",
          //   label: "Past Licenses",
          //   content: {
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "past_contracts_trainer",
          //           fields: [
          //             // "has_expired_gyrotonic_license",
          //             // "has_expired_gyrokinesis_license",
          //             "past_contracts_trainer"
          //           ]
          //         }
          //       ]
          //     }
          //   }
          // },

          // {
          //   id: "CoursesTab",
          //   label: "Courses",
          //   content: {
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "completed_courses",
          //           fields: ["completed_courses"]
          //         }
          //         // {
          //         //   id: "gk_courses",
          //         //   fields: ["gk-courses"]
          //         // }
          //       ]
          //     }
          //   }
          // },
          {
            id: "StudiosTab",
            label: "Studios",
            content: {
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "studios",
                    fields: ["studios", "blocked_studios"],
                  },
                ],
              },
            },
          },
          // {
          //   id: "NotificationsTab",
          //   label: "Notificationsstudios
          //   content: studios
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "notifications",
          //           fields: ["notifications"],
          //         },
          //       ],
          //     },
          //   },
          // },
          // {
          //   id: "NotesTab",
          //   label: "Notes",
          //   content: {
          //     type: "FieldSetGridLayout",
          //     opts: {
          //       fieldSets: [
          //         {
          //           id: "notes",
          //           fields: ["notes"]
          //         }
          //       ]
          //     }
          //   }
          // }
        ],
      },
    },
  ];

  protected getDefaultModelLayoutId(): string {
    return this.$nuxt.$auth.user.is_admin ? "flat-layout" : "trainer-tabs";
  }

  protected static collectionLayoutDefinitions = extendWithDeleted([
    {
      id: "table",
      inlineEdit: false,
      enableMultiSort: true,
      useModal: false,
      columnFields: [
        "created_at",
        "last_name",
        "first_name",
        "country",
        "state",
        "city",
        "email",
      ],
      // filterFields: [
      //   "first_name",
      //   "last_name",
      //   "wechat",
      //   "filter_email",
      //   "filter_qualifications",
      //   "filter_courses"
      // ],
      enableSearch: true,
      searchLabel: "Search by First Name, Last Name, or Email",
      enableDynamicFilters: true,
      collectionActionComponents: ["collection-export"],
      dynamicFilterOptions: {
        filterFields: [
          "created_at",
          "updated_at",
          "first_name",
          "filter_first_name",
          "filter_middle_name",
          "filter_last_name",
          "filter_facebook",
          "filter_twitter",
          "filter_email",
          "filter_website",
          "filter_email_public",
          "filter_instagram",
          "filter_youtube",
          "filter_native_fullname",
          "notes.subject",
          "notes.body",
          "wechat",
          "mobile_phone",
          "is_email_opted_out",
          "is_active",
          "id",
          "legacy_id",
          "has_expired_gyrotonic_license",
          "has_active_gyrotonic_license",
          "has_expired_gyrokinesis_license",
          "has_active_gyrokinesis_license",
          "address1",
          "state",
          "substate",
          "city",
          "zip",
          "country",
          "user",
          "trainer_qualifications.qualification",
          "completed_courses.course",
          "completed_courses.instructor",
          "studios",
          "blocked_studios",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
    {
      id: "licensed",
      disableRowClick: true,
      columnFields: [
        "last_name",
        "first_name",
        "email",
        "has_active_gyrotonic_license",
        "has_active_gyrokinesis_license",
        "completed_courses_tooltip",
      ],
      // filterFields: ["first_name", "last_name", "filter_email"],
      enableSearch: true,
      searchLabel: "Search by First Name, Last Name, or Email",
      enableDynamicFilters: false,
      collectionActionComponents: ["collection-export"],
      filters: [
        new CrudFilter({
          name: "static_is_active",
          query: {
            is_active: 1,
          },
        }),
      ],
      defaultSort: [
        {
          field: "first_name",
          order: "asc",
        },
        {
          field: "last_name",
          order: "asc",
        },
      ],
    },
    {
      id: "non-admin",
      columnFields: [
        "last_name",
        "first_name",
        "email",
        "is_active",
        "updated_at",
      ],
      // filterFields: ["first_name", "last_name", "filter_email"],
      enableSearch: true,
      searchLabel: "Search by First Name, Last Name, or Email",
      enableDynamicFilters: false,

      filters: [
        new CrudFilter({
          name: "user_owned",
          query: {
            _user_owned: 1,
          },
        }),
      ],
      defaultSort: [
        {
          field: "first_name",
          order: "asc",
        },
        {
          field: "last_name",
          order: "asc",
        },
      ],
    },
  ]);

  public static instructorFilters = [
    new CrudFilter({
      name: "is_instructor",
      queryValue: "1",
      isStatic: true,
    }),
    new CrudFilter({ name: "is_active", queryValue: "1", isStatic: true }),
  ];
}
